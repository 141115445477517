import React, { useState } from "react";
import Heart from "react-animated-heart";

export default class HeartLike extends React.Component{

    state ={
        isClick: this.props.isClick
    }

    setClick = () => {

      const {isClick} = this.state;

      let {count} = this.props;
      let newCount = count;
      
      if(!isClick){
        newCount = parseInt(count) + 1;
      } else{
        newCount = parseInt(count) - 1;
      }

      this.props.onClick(newCount, isClick);

      this.setState(prevState => ({
        isClick: !prevState.isClick
      }));

    }

    render(){

      const { isClick } = this.state;
      const { count }   = this.props;

      return(
        <div className="heartCount">
          <div className="heart">
            <Heart isClick={isClick} onClick={ () => this.setClick(count) }/>
          </div>
          <div className="count">{count}</div>
        </div>
      )
    }

}