import React from 'react';
import HeartLike from './Heart';
import instance from './axiosConfig';
import Cookies from 'universal-cookie';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


export default class Post extends React.Component{

    state = {
        likes: this.props.post.acf.likes || 0
    }


    handleClick = (count, isClick) => {

        const cookies = new Cookies();

        let likes = cookies.get('likes') || [];

        if(!isClick){
            likes.push(this.props.post.id);
        } else{
            likes = likes.filter(item => item !== this.props.post.id)
        }

        cookies.set('likes', likes, { path: '/' });


        this.setState({likes: count});
        this.updatePostLikes(count);
    }

    updatePostLikes = (count) => {


        instance.post(`/wp/v2/posts/${this.props.post.id}`, {
            "fields": {
                "likes": count
            }
        })
        .then(res => {
            
        }).catch(function(error){
            console.log(error.message);
        });


    }

      render(){

        const {post} = this.props;

        let link = 'blog' + post.permalink;

        const cookies = new Cookies();

        let likes = cookies.get('likes') || [];

        let isClick = false;

        if(likes.includes(post.id)){
            isClick = true;
        } 



        return (
            <li className="day day--blog">
                <h2>{post.title.rendered}</h2>
                <HeartLike isClick={isClick} count={this.state.likes} onClick={this.handleClick}></HeartLike>
                <div className="summary" dangerouslySetInnerHTML={{__html: post.excerpt.rendered}} />
                <div>
                    {post.featured_image_url && (
                        <div>
                            {this.renderImage(post.featured_image_url, post.featured_image_caption, post.featured_image_dimensions.width, post.featured_image_dimensions.height)}
                        </div>
                    )}
                </div>
                <Link className="read_more" to={link}>Read More</Link>
            </li> 
        );
    }


}