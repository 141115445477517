import React from 'react';
import Posts from './Posts';
import Post from './Post';

export default class BlogPosts extends Posts{
    

    render(){

        return(
            <div>
                <ul className="posts">
                    {this.state.posts.map(post => <Post post={post} />)}
                </ul>
                {this.renderFooter()}
            </div>
        )

    }


}