import React from 'react';
import {Helmet} from "react-helmet";

import instance from './axiosConfig';

import FlexibleContent from './FlexibleContent';
import Posts from './Posts';
import Comments from './Comments';
import { Redirect } from "react-router-dom";


export default class Page extends Posts{

    state = {
        page: false, 
        isLoading: true,
        notFound: false
    }

    componentDidMount(){
        this.loadPosts();
    }


    loadPosts = () =>  {
        // return <Redirect to='/not-found' />
        let requestUrl = '/my-plugin-slug/permalink?url=/' + this.props.permalink[0] ;
        let fetchUrl = requestUrl;
        let notFound = this.state.notFound;
        instance.get(fetchUrl)
        .then(res => {
            if(!res.data){
                notFound = true;
            }
            this.setState({
                page: res.data,
                isLoading: false,
                notFound: notFound
            });
        }).catch(function(error){
            console.log(error.message);
        });
    }

    // handleLove = (event) => {
    //     console.log("Handle the love");
    // }


    renderBlogPost(post){

        console.log(post);

        if(!post){
            return;
        }

        return (
            <>
                <Helmet>
                    {/* <title>{post.}</title> */}
                    {post.yoast_meta.map(meta_value => {
                        return (
                            <meta name={meta_value.name || meta_value.property} content={meta_value.content} />
                        );
                    })}
                </Helmet>

                <div className="content">
                    <h1 className="date"><span dangerouslySetInnerHTML={{__html: post.title.rendered}}></span></h1>
                    <div dangerouslySetInnerHTML={{__html: post.content.rendered}}></div>
                    {post.acf.flexible_content && (
                       <div>
                           {post.acf.flexible_content.map(flexible_content => 
                            <FlexibleContent content={flexible_content} />
                            )}
                       </div>
                       
                    )}
                </div>
                <div>
                    {post.featured_image_url && (
                        <div>
                            {this.renderImage(post.featured_image_url, post.featured_image_caption, post.featured_image_dimensions.width, post.featured_image_dimensions.height)}
                        </div>
                    )}
                </div>
            
                {/* <i onClick={this.handleLove} className="far fa-heart love"></i> */}
                {(this.props.post_type=="post") && (
                    <Comments post={post.id}></Comments>
                ) }

            </>
        );
    }

    render(){

        let {page, notFound} = this.state;

        // if(notFound){
        //     return <Redirect to='/not-found' />
        // }

        return(
            <article className="posts">
                {this.renderBlogPost(page)}
            </article>
        )

    }


}