import React from 'react';
import NotFound from './NotFound';
// import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import Posts from './Posts';
import Page from './Page';
import BlogPosts from './BlogPosts';

// import logo from './logo.svg';
import './App.scss';


class App extends React.Component{

  render(){
    return (
      <div className="App">
            <Router>
              <header>
                <div className="container">
                  <nav>
                    <Link to="/">myfrugal.life</Link>
                    <Link to="/about">About</Link>
                    {/* <Link to="/saving-tips">Saving Tips</Link> */}
                  </nav>
                </div>
              </header>
              <main>
                 <div className="container">
                  <Switch>
                    {/* <Route path="/saving-tips">
                      <h1>Saving tips.</h1>
                      <BlogPosts key="saving-tips" url="wp/v2/posts?categories=3" perPage="1"></BlogPosts>
                    </Route> */}
                    <Route path="/not-found" status={404}>
                      <NotFound></NotFound>
                    </Route>
                    <Route key="blog-post" path="/blog/*" render={(props) => 
                        <Page perPage="1" post_type="post" permalink={props.match.params} ></Page> 
                    } />
                    {/* <Route path="/page" render={(props) => 
                        <Page url="wp/v2/posts/114" perPage="1" id={props.match.params.id} ></Page> 
                    } /> */}
                    {/* <Route path="/">
                      <h1>Saving Tips</h1>
                      <BlogPosts key="saving-tips" url="wp/v2/posts?categories=3" perPage="1"></BlogPosts>
                    </Route> */}
                    <Route exact path="/" render={(props) => 
                      <BlogPosts key="saving-tips" url="wp/v2/posts?categories=3" perPage="1"></BlogPosts>
                    } />
                    <Route key="page" path="/*" render={(props) => 
                        <Page perPage="1" post_type="page" permalink={props.match.params} ></Page> 
                    } />
                  </Switch>
                 </div>
              </main>
            </Router>
         </div>
    );
  }

}

export default App;
