import React from 'react';
import instance from './axiosConfig';

import CommentList from './CommentList';
import CommentForm from './CommentForm';

export default class Comments extends React.Component{

    state = {
        currentPage: 0,
        numberofComments: 0,
        isLoading: true,
        comments: [],
        commentsCurrentPage: 0,
        commentsPerPage: 1,
        commentsTotalPages: 0,
        isError: false,
        resetForm: false,
        hasPosted: false,
        newComment: null
    }

    componentDidMount(){
        this.loadCommnets();
    }



    handleSubmit = (form, resetForm) => { 

        let {author_email, author_name, comment} =  form;

        instance.post('/wp/v2/comments', {
            author_name: author_name,
            author_email:author_email,
            post : this.props.post,
            content : comment
        })
        .then(res => {

            resetForm();

            this.setState({
                author_name: "",
                author_email: "",
                content: "",
                hasPosted: true,
                // comments: updatedComments, 
                newComment: res.data
            });
            
        }).catch(error => {
            console.log(error);
        });
       
    }

    handleChange = (event) =>  {

        const target = event.target;
        const name   = target.name;
        const value  = target.value;

        this.setState({
            [name]: value
        });

    }

    loadCommnets = () =>  {

        let {post} = this.props;
        let page = this.state.commentsCurrentPage + 1;  
        let perPage    = this.state.commentsPerPage ? this.state.commentsPerPage : 1;

        let exclude = '';
        if(this.state.newComment){
            exclude = '&exclude[]=' + this.state.newComment.id;
        }

        let requestUrl = 'wp/v2/comments?post=' + post + '&page=' + page + "&per_page=" + perPage + exclude;
        let fetchUrl = requestUrl;
        instance.get(fetchUrl)
        .then(res => {
            const comments = [...this.state.comments];
            let updatedComments = comments.concat(res.data);
            this.setState({
                comments: updatedComments,
                totalComments: res.headers['x-wp-total'], 
                commentsTotalPages: res.headers['x-wp-totalpages'],
                isError: false,
                commentsCurrentPage: page
            });
        }).catch(error => {
            this.setState({
                isError: true
            });
        });
    }

    
    render(){


        const {commentsCurrentPage, commentsTotalPages, hasPosted, comments, newComment} = this.state;

        let allComments = comments;

        if(newComment){
            allComments  = comments.concat(newComment);
            console.log("Comment should be added now");
        }
        
      

        // console.log("All comments:");
        // console.log(allComments);

        let loadMore = '';

        // let commentForm = <CommentForm onChange={this.handleChange} onSubmit={this.handleSubmit} author_name={this.state.author_name} author_email={this.state.author_email} comment={this.state.content} ></CommentForm>;

        // if(hasPosted){
        //     commentForm = <div className="comments">
        //         <div>Comment Added.</div>
        //     </div>
        // }

        console.log(`Comments total: ${comments.length}`);
        if(comments.length===0){
            console.log("foobar");
        }

        if(commentsCurrentPage<commentsTotalPages){
            loadMore = <button className="loadmore" onClick={this.loadCommnets}>Load More Comments</button>;
        } else if(comments.length==0){
            loadMore = <button className="loadmore" disabled>No comments so far</button>;
        } 
        else{
            loadMore = <button className="loadmore" disabled>End of Comments </button>;
        }

        
        return(
            <div>
                <h3>Comments</h3>
                <CommentList comments={allComments} onComponentDidMount={this.loadCommnets} post="175"></CommentList>
                <p>Like this post? Any ideas how to save more? Comment below. I'd love to hear your ideas on how to save more.</p>
                {loadMore}
                {hasPosted ? (
                    <div className="comments">
                        <div>Comment Added.</div>
                    </div>
                ) : (
                    <CommentForm onChange={this.handleChange} onSubmit={this.handleSubmit} author_name={this.state.author_name} author_email={this.state.author_email} comment={this.state.content} ></CommentForm>
                )}
            </div>
        );

    }
   
}