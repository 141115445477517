import React from 'react';
import instance from './axiosConfig';
import Moment from 'moment';

// import FlexibleContent from './FlexibleContent.js';



export default class Posts extends React.Component{

    state = {
        posts: [], 
        currentPage: 0,
        numberOfPages: 0,
        isLoading: true
    }

    componentDidMount(){
        this.loadPosts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.permalink !== this.props.permalink) {
            this.loadPosts();
        }
    }


    loadPosts = () =>  {
        let page = this.state.currentPage + 1;  
        let requestUrl = this.props.url ;
        let perPage    = this.props.perPage ? this.props.perPage : 2;
        let fetchUrl = requestUrl + "&per_page=" + perPage + "&page=" + page;
        this.setState({
            isLoading: true,
        });
        instance.get(fetchUrl)
        .then(res => {
            const posts = [...this.state.posts];
            let updatedPosts = posts.concat(res.data);
            let currentPage = this.state.currentPage + 1;
            
            this.setState({
                posts: updatedPosts,
                currentPage: currentPage,
                isLoading: false,
                totalPages: res.headers["x-wp-totalpages"]
            });
            
        }).catch(function(error){
            console.log(error.message);
        });
    }


    renderBreakDown(breakdown){
        if(breakdown){
            return <div>{breakdown.map(breakdown =>  <li><i className={"fas fa-" + breakdown.icon}></i> <span dangerouslySetInnerHTML={{__html: breakdown.description}}></span> - £{breakdown.total}</li>)}</div>
        }
    }


    renderPost(post){
       
        return this.renderDefaultPost(post);

    }


    // post content
    // acf total
    //  acf breakdown

    renderDefaultPost(post){

        let randomString = Math.random().toString(36).substring(7);
    
        return (
            <li key={randomString} className="day">
                <div className="summary">
                    <h3 className="date"><i className="far fa-clock"></i>{Moment(post.date).format('MMMM Do, GGGG')}</h3>
                        <div dangerouslySetInnerHTML={{__html: post.content.rendered}} />
                </div>
                <div className="spend">
                    <span className="spend__total">Total: £{post.total}</span>
                    <ul className="spend__breakdown">
                        {this.renderBreakDown(post.acf.breakdown)}
                    </ul>
                </div>
            </li>
        );
    }


    renderImage(url,caption, width, height){
        return (
            <figure>
                <img src={url} width={width} height={height} />
                <figcaption>{caption}</figcaption>
            </figure>
        );
    }


    renderFooter = () => {
        const {currentPage, isLoading, totalPages} = this.state;
        if(isLoading){
            return <h2 className="endofposts">Loading...</h2>
        }
        if(currentPage<totalPages){
            return <button className="loadmore" onClick={this.loadPosts}>Load More</button>
        } else{
            return <h2 className="endofposts">End of Posts</h2>
        }
    }
 

    render(){

        const {isLoading} = this.state;

        if(isLoading){
            return (
                <div>Fetching posts..</div>
            );
        }

        return(
            <div>
                <ul className="posts">
                    {this.state.posts.map(post => this.renderPost(post))}
                </ul>
                {this.renderFooter()}
            </div>
        )
    }


}