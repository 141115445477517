import React from 'react';
import instance from './axiosConfig';
import moment from 'moment';


export default class CommnetList extends React.Component{


    renderComments = () => {

        let comments = this.props.comments;

        return(
            <div>
                {comments.map(comment => 
                  <div className="comment">
                       <div className="comment__meta">
                            <img className="comment__author_image" src={comment.author_avatar_urls[96]} />
                            <div>
                                <p>{comment.author_name}</p>
                                <p>{moment(comment.date).format('LL, HH:mm A')}</p>
                            </div>
                       </div>
                       <div className="comment__content" dangerouslySetInnerHTML={{__html: comment.content.rendered}}></div>
                  </div>
                )}
            </div>
        )
    }

    

    render(){

        let comments =  <div>{this.renderComments()}</div>;

        return(
            <div className="comments">
                {/* <h3>{this.state.totalComments} Comments</h3> */}
                {comments}
            </div>
        )

    }


}