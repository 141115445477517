import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://media.myfrugal.life/wp-json/'
});

instance.interceptors.request.use(request => {
    if(request.method=="post"){
        request.headers = {
            Authorization: `Basic Z3JhaGFtZTpEQHJrbGluZyEy`
        }
    }
    return request
});

export default instance;