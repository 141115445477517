import React from 'react';
import instance from './axiosConfig';
import { Field, Form, Formik, FormikProps, ErrorMessage, textarea } from 'formik';
import * as yup from 'yup'; 

export default class CommentForm extends React.Component{

    defaultFormState = {
        author_email: '',
        author_name: '',
        comment: '',
    }

    componentDidMount(){
        // this.loadCommnets();
    }


    handleSubmit = (form, {resetForm}) => {

        this.props.onSubmit(form, resetForm);
    }

    handleChange = (event) => {
        this.props.onChange(event);
    }


    render(){

        return(
            <div className="comments">
                <h3>Leave a reply</h3>
                    <Formik
                onSubmit={this.handleSubmit}
                initialValues={this.defaultFormState}
                validationSchema={validationSchema}
            >
            {() => (
                <Form>
                    <div class="input_control">
                        <Field name="author_name" type="text" placeholder="Name"/>
                        <ErrorMessage className="error" name="author_name" component="div" />
                    </div>
                    <div class="input_control">
                        <Field name="author_email" type="email" placeholder="Email"/>
                        <ErrorMessage className="error" name="author_email" component="div" />
                    </div>
                    <div class="input_control">
                        <Field as="textarea" name="comment" type="email" placeholder="Comment"/>
                        <ErrorMessage className="error" name="comment" component="div" />
                    </div>
                    <div class="input_control">
                        <button type="submit">Post Comment</button>
                    </div>
                </Form>
            )}
            </Formik>
            </div>
        )

    }

}

const validationSchema = yup.object({
    author_email: yup.string()
      .email('Invalid email')
      .required('Required'),
    author_name: yup.string()
      .required('Required'),
    comment: yup.string()
        .required('Required')
});